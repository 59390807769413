@import './variables.scss';

/**
 * Flex Grid -
 * Version: 0.3.5
 *
 * Simple grid built with flex box and sass.
 *
 * Matthew Simo - matthew.a.simo@gmail.com
 * @link https://matthewsimo.github.io/scss-flex-grid/
 */

/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $fg-columns will inform the grid loops how many columns there should be.
 * $fg-gutter will inform the grid loops how big eac column's gutters should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $fg-class-row string used for the row class
 * $fg-class-col string used for the column class
 * $fg-class-off string used for the offset class
 */
$fg-columns: 12 !default;
$fg-gutter: $container-gap !default;

$fg-class-row: "row" !default;
$fg-class-col: "col" !default;
$fg-class-off: "off" !default;

/**
  * Break point namespace object
  *
  * Set the default namespace object with these defaults with the
  * understanding that you can pass in whatever you might require for your site.
  *
  * $fg-breakpoints is a Sass list with nested lists inside. Each sub list defines two things.
  * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
  * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
  *
  * Note: These should be in the proper order (at least till libsass handles map keys properly).
  *
  * Note: If the measurement is left out then it will be skipped when generating
  * the grid and applied to global styles.
  *
  */
$fg-breakpoints: ((xs), (sm, 768px), (md, 992px), (lg, 1200px)) !default;

/**
  * Class Name Defaults
  *
  * Define class names for columns, rows and offsets in case compatibility with other
  * libraries is necessary.
 
 
 /**
  * Calculate column size percentage
  */
@function get-col-percent($column-num) {
  @return $column-num / $fg-columns * 100%;
}

/**
  * Spacing mixin to create uniform margin/padding
  */
@mixin spacing($value, $type: margin, $orientation: vertical) {
  @if $orientation == vertical {
    #{ $type }-top: $value;
    #{ $type }-bottom: $value;
  } @else {
    #{ $type }-left: $value;
    #{ $type }-right: $value;
  }
}

/**
  * Row wrapper class, flex box parent.
  */
.#{ $fg-class-row } {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: inherit !important;
  @include spacing((-$fg-gutter), margin, horizontal);
}

%#{ $fg-class-col } {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 1px;
  @include spacing(($fg-gutter), padding, horizontal);
}

%#{ $fg-class-col }-flex {
  @extend %#{ $fg-class-col };
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

/**
  * Generate a set of grid column classes using a namespace
  *
  * .#{ $fg-class-col }-[namespace] for intelligent column division
  * .#{ $fg-class-col }-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
  * .#{ $fg-class-off }-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
  * .#{ $fg-class-off }-[namespace]-reset for resetting a col's offset for that and larger namespaces
  */

@mixin grid-base($namespace) {
  .#{ $fg-class-col }-#{$namespace} {
    @extend %#{ $fg-class-col }-flex;
  }

  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      @extend %#{ $fg-class-col };
    }
  }
}

@mixin grid-sizes($namespace) {
  // Run the loop for as many columns as speficied
  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      flex-basis: get-col-percent($i);
      max-width: get-col-percent($i);
    }
  }

  @for $i from 1 through $fg-columns {
    // Don't include the .off class for the last column
    @if ($i != $fg-columns) {
      .#{ $fg-class-off }-#{$namespace}-#{$i} {
        margin-right: get-col-percent($i);
      }
    }
  }

  .#{ $fg-class-off }-#{$namespace}-reset {
    margin-right: 0;
  }
}

/**
  * Build the grid in two steps, to help minimize file size
  * Step 1, for each namespace, create the grid-base
  * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
  */
@each $val in $fg-breakpoints {
  $namespace: nth($val, 1);
  @include grid-base($namespace);
}

@each $val in $fg-breakpoints {
  @if length($val) == 1 {
    $namespace: nth($val, 1);
    @include grid-sizes($namespace);
  } @else {
    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      @include grid-sizes($namespace);
    }
  }
}

