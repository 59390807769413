@import "../../variables.scss";

.page {
  display: flex;
}

.searchWrapper {
  background: $primary-color;
  color: white;
  & > div {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
}

div.languageSelect,
div.dateSelect,
div.specialtySelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: $border-radius;
  height: 40px;
  line-height: 40px;
  @include onMobile(#{&}) {
    margin-bottom: 0.5rem;
  }

  img {
    width: 15px;
    vertical-align: middle;
    margin-right: 0.4rem;
  }
}

div.dateSelect {
  padding: 0 1rem;
}

button.searchButton {
  background: white !important;
  width: 40px;
  height: 40px;
  margin-left: 1rem;
}

.sidebar {
  width: 280px;
  margin-right: 20px;
}

.main {
  width: calc(100% - 300px);
  @include onMobile(#{&}) {
    width: 100%;
  }
}

.pagination {
  margin-top: 1rem;
  flex-direction: row;
  justify-content: end;
  @include onMobile(#{&}) {
    justify-content: center;
  }
}

.dateSelect {
  cursor: pointer;
  input {
    cursor: pointer;
  }
}

:global(.Consultants .searchWrapper) {
  overflow: hidden;

  :global(.MuiFormLabel-root),
  :global(.MuiInputLabel-root) {
    display: flex;
    position: absolute;
    white-space: nowrap;
    transform: translateY(33%);
    left: 1rem;
    top: 0;
  }

  :global(.MuiInputBase-root) {
    flex: 1;
  }

  :global(.MuiInputBase-root) {
    border: none !important;
    margin-top: 0;
    &:after,
    &:before {
      border: none !important;
    }
  }

  :global(.MuiInputBase-input) {
    padding-left: 70px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    height: 40px;
    line-height: 40px;
  }

  :global(.MuiSelect-standard) {
    padding: 0;
    padding-left: 114px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    height: 40px;
    line-height: 40px;
  }
}