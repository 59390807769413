@import '../../variables.scss';

.notification{
  padding: 1rem;
  display: flex;
  @include onMobile(#{&}){
    margin-bottom: .5rem;
    padding: .5rem;
    flex-direction: column;
    border-bottom: $border-regular;
  }
}
.description{
  @include onMobile(#{&}){
    font-size: $font-size-sm;
  }
}
.meta{
  @include onMobile(#{&}){
    margin-top: .5rem;
    justify-content: space-between;
  }
}

.avatarWrapper{
  @include onMobile(#{&}){
    :global(.MuiAvatar-root){
      width: 30px;
      height: 30px;
    }
  }
}

.unread{
  background: rgb(240, 240, 240);
}

.pagination {
  margin-top: 1rem;
  flex-direction: row;
  justify-content: end;
  border-radius: $border-radius;
}

