@import "../../variables.scss";

.AccountInformation {

  .MuiFormControl-root,
  .MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0.5rem;
  }
  
  .changePassword {
    button {
      text-transform: inherit;
      color: $text-primary;
    }
  }

  .changePassword button,
  .MuiInputLabel-formControl,
  .MuiFormControlLabel-label {
    color: $text-primary;
    font-weight: bold;
  }
}
