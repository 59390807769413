@import "../../variables.scss";

.header {
  height: $header-height;
  background: #444445;

  .headerInner {
    @include container;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .backButton {
    background: transparent;
    border: none;
    margin-right: 2rem;
    cursor: pointer;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
  }

  .logoType {
    font-size: 32px;
    font-weight: normal;
    margin-left: 1rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  $userMenuHeight: 40px;

  .loginButton {
    background-color: white !important;
    padding: 0.7rem 1rem;
    color: $text-primary !important;
    text-decoration: none;
    text-transform: inherit !important;
    border-radius: $border-radius !important;
    padding: 0 0.5rem !important;
    height: $userMenuHeight;
    line-height: $userMenuHeight;
    min-width: 240px !important;
  }

  .userMenuButton {
    @extend .loginButton;
    max-width: 230px;
    min-width: 240px !important;
    white-space: nowrap;
    display: flex;
  }

  .name {
    margin-right: 1rem;
    flex: 1;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
}

.isMobile {
  .header {
    background: white;
    box-shadow: 0px 0.7px 0px rgba(0, 0, 0, 0.09);
    .logoWrapper {
      color: $text-primary;
      img {
        display: none;
      }
    }

    .backButton {
      margin-right: 0.2rem;
      display: flex;
      img {
        filter: invert(1);
        width: 20px;
        height: 20px;
      }
    }

    .logoType {
      font-size: 14px;
      font-weight: bold;
      margin-left: 1rem;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }

    .loginButton {
      min-width: auto !important;
    }

    .name {
      display: none;
    }

    .userArea {
      & > div {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
