@import-normalize;

@import "variables";
@import "flex-grid";
@import "generics";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --colors-red: #{$color__red};
  --colors-yellow: #{$color__yellow};
  --colors-green: #{$color__green};
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  // overflow-x: hidden;
  font-size: 16px;
  height: -webkit-fill-available;
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
}

main {
  min-height: 30vh;
}

body {
  background: $bg-color;
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  cursor: default;
  font-weight: normal;
  color: $text-primary;
  font-size: 0.875rem; // for 16px based size, its eq. to 14px
  line-height: $line-height-body;

  @media #{$large-and-up} {
    font-size: 1rem;
  }
}

.container {
  @include container;
}

.content {
  padding-top: 20px;
  padding-bottom: 30px;

  @media #{$large-and-up} {
    min-height: 45vh;
    padding-top: $header-height;
    padding-bottom: $header-height + 30px;
  }
}

h1 {
  font-size: $font-size-h1;
  line-height: 2rem;
}
h2 {
  font-size: 24px;
  @media #{$large-and-up} {
    font-size: $font-size-h2;
  }
}

h3 {
  font-size: 20px;
  line-height: 2rem;
  @media #{$large-and-up} {
    font-size: $font-size-h3;
  }
}

h5 {
  font-size: 20px;
  line-height: 1.8rem;
  font-weight: normal;
  @media #{$large-and-up} {
    font-size: $font-size-h5;
  }
}

h6 {
  font-size: 20px;
  line-height: 1.8rem;
  margin: 0;
  @media #{$large-and-up} {
    font-size: $font-size-h6;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
}

p {
  @include text;
  &:first-letter {
    text-transform: uppercase;
  }
}

button {
  white-space: nowrap;
}

hr {
  border: none;
  height: 1px;
  color: rgb(233, 233, 233);
  background-color: rgb(233, 233, 233);
}
// .MuiInputBase-sizeSmall{
//   // font-size: 1.3em !important;
//   // line-height: 1.3em !important;
//   input{
//     padding-top: 12px;
//     padding-bottom: 12px;
//   }
// }

.widget {
  margin-bottom: 2rem;

  .widget__title {
    font-weight: normal;
    letter-spacing: $letter-spacing-low;
    margin: 1rem 0;
  }
}

.header {
  .MuiBadge-badge {
    transform: scale(0.8) translate(30%, -20%);
  }
}
.noDataMessage {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title,
  p {
    color: $text-tertiary;
  }
}

.authDialog {
  .MuiOutlinedInput-root {
    border-radius: 8px;
    input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  h2 + p {
    font-size: $font-size-p2 !important;
    margin: -0.5rem 0 1rem 0;
  }
}

#user-menu {
  .MuiPaper-root {
    margin-top: 2px;
    min-width: 240px;
  }
}

.MuiFormLabel-asterisk {
  color: $primary-color !important;
}

.AccountInformation,
.ProfileCompletion {
  .MuiFormControl-root {
    margin-bottom: 1rem;

    .MuiSelect-select,
    input {
      padding: 0.7rem 0;
    }

    .MuiInputLabel-root {
      color: $text-primary;
      font-weight: bold;
      font-size: 20px;
      &.Mui-focused {
        color: $primary-color;
      }
    }
  }
}

.MuiOutlinedInput-root {
  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.MuiInputBase-sizeSmall {
    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: translate(14px, 12px) scale(1);
  &.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.8);
  }
}

.MuiDialog-root {
  &.centered {
    .MuiDialogTitle-root {
      text-align: center;
      padding: 30px;
    }
    .MuiDialogActions-root {
      justify-content: center;
      padding: 15px;
    }
  }
}

.MuiDialog-container {
  @media #{$large-and-up} {
    & > div {
      min-width: 550px;
    }
  }
}

h2.MuiDialogTitle-root {
  // font-size: $font-size-lg - 0.2;
  // font-weight: bold;
  // padding: 0.1rem 0;
  line-height: 1.6em;
}

div.MuiDialogContent-root {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: $border-radius;

  &.MuiDialog-paperFullScreen {
    border-radius: 0;
  }
}

ul.MuiMenu-list {
  padding: 0;

  li {
    font-size: $font-size-sm;
    letter-spacing: 0.02em;
    padding: 0.7rem 1rem;
  }
}

a.read-more {
  color: $primary-color;
  text-decoration: none;
}

a.MuiMenuItem-root,
li.MuiMenuItem-root {
  letter-spacing: 0.02em;
  padding: 0.7rem 1rem;
  font-size: 0.875rem;
  &:hover {
    background-color: rgba($primary-color, 0.06);
  }
}

.MuiTouchRipple-child {
  background-color: $primary-color !important;
}

div.MuiDialogContent-root {
  flex-grow: 0;
}
.MuiAvatar-img {
  background: rgb(235, 235, 235);
}

.userMenu {
  .MuiBadge-dot {
    margin-left: 7px;
    margin-top: 7px;
    background: rgb(255, 115, 0);
  }
}
.UserPanel {
  main header {
    button {
      font-size: 20px;
      text-transform: capitalize;
      font-weight: 700;
    }
  }
  .MuiMenuItem-root {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 0.5rem;
    border-radius: $border-radius;
  }
}

.isMobile {
  .Home {
    .serviceSlider {
      .slide {
        position: relative;
      }

      img {
        height: 80vh;
        width: auto;
        border-radius: 0;
      }

      h3 {
        background: #3da3f7b2;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: $border-radius;
        position: absolute;
        bottom: 2rem;
        left: 1rem;
        letter-spacing: 0;
        font-size: 18px;

        svg {
          vertical-align: middle;
        }
      }
    }
  }
}

$slickDotsSize: 10px;

.slick-slider .slick-dots {
  bottom: 15px;

  li {
    margin: 0;
    padding: 0;
    width: $slickDotsSize;
    height: $slickDotsSize;

    button {
      padding: 0;
      width: $slickDotsSize;
      height: $slickDotsSize;
      &:before {
        color: white;
        opacity: 1;
        width: $slickDotsSize;
        height: $slickDotsSize;
        line-height: $slickDotsSize;
      }
    }

    &.slick-active button:before {
      opacity: 1;
      color: $primary-color;
    }
  }
}

.slick-dots li.slick-active button::before {
  color: $primary-color;
}
