@import "../../variables.scss";

.ScheduleMeetingDialog {
  .week {
    display: flex;
    // justify-content: center;
    overflow-y: auto;
  }

  $size: 45px;

  button.prev,
  button.next,
  button.day {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: $size !important;
    width: $size;
    height: $size;
    line-height: $size;
    border-radius: 100%;
    text-align: center;
    margin: 0.3rem;
    font-size: $font-size-sm;
    text-transform: inherit;

    &:not([disabled]):not(.MuiButton-contained) {
      background: $color__light-green;
      color: $text-primary;
    }
    &.allReserved {
      background: rgb(255, 166, 166) !important;
    }

    & > span {
      line-height: 1rem;
      font-size: 0.7em;
      // color: $text-secondary;

      &:nth-child(2) {
        font-size: 1rem;
        // color: $text-primary;
      }
    }
  }

  @media #{$large-and-up} {
    button.prev,
    button.next,
    button.day {
      min-width: 60px !important;
      width: 60px;
      height: 60px;
      line-height: 60px;

      & > span {
        line-height: 1.2rem;
        font-size: 0.875em;
        // color: $text-secondary;
  
        &:nth-child(2) {
          font-size: $font-size-lg;
          // color: $text-primary;
        }
      }
    }
  }


  // @at-root .isMobile &{
  //   button.prev,
  //   button.next{
  //     width: 40px;
  //     height: 40px;
  //   }
  // }

  button.prev:not([disabled]),
  button.next:not([disabled]) {
    background: whitesmoke;
    color: $text-primary;
  }

  .time {
    width: 100%;
    // background: whitesmoke;
    border-radius: 30px;
    margin: 0 0 0.5rem;
    // color: $text-primary;
    text-align: center;
    box-shadow: $shadow-d1;
    cursor: pointer;
    transition: 200ms;
    height: 35px;
    line-height: 35px;
    padding: 0rem 1rem;

    &.occupied {
      background: rgb(255, 185, 185);
      pointer-events: none;
      color: white;
      border: none;
    }

    &.active {
      background: var(--primary-color);
      color: white;
      //transform: translateY(5px) scale(0.9);
    }
  }
}
