@import "../../variables.scss";

.footer {
  background: #444445;
  padding: 4rem 2rem;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      margin-bottom: 1rem;
    }
  }

  h3 {
    font-size: 1rem;
    margin: 0 0 1rem 0;
    color: white;
    white-space: nowrap;

    @media #{$medium-and-up} {
      font-size: $font-size-h5;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.logoType {
  display: inline-block;
  font-size: 32px;
  font-weight: lighter;
  margin-top: 1rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  text-decoration: none;
  margin-bottom: 3rem;
}

.widget {
}

.socialLinks {
  text-align: center;
  margin-top: 1rem;
  a {
    margin: 0 0 1rem 0;
    display: flex;
    align-items: center;

    @media #{$medium-and-up} {
      margin: 0 1rem;
      display: inline-block;
    }

    img {
      width: 22px;
      height: 22px;
      filter: invert(1);
    }
    span {
      margin-left: 1rem;
      @media #{$medium-and-up} {
        display: none;
      }
    }
  }
}
