@import "../../variables.scss";

.card {
  display: flex;
  border-bottom: 0.4px solid #dfdfdf;
  padding: 1.5rem 1rem;
  align-items: center;

  @include onMobile(#{&}) {
    align-items: start;
    padding: 1rem 0.3rem;
  }
}

.avatarWrapper {
  img,
  div {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: $border-radius;
    background: whitesmoke;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    @include onMobile(#{&}) {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
  }
}

.contentWrapper {
  padding: 0 1rem;
  flex: 1;

  @include onMobile(#{&}) {
    padding: 0;
    margin-left: 0.5rem;
  }
}

.metaWrapper {
  display: flex;
  align-items: center;
  color: $text-secondary;
}

.name {
  line-height: 1.2em;
  margin: 0 0 0.5rem 0;
  font-size: $font-size-h5;
  font-weight: normal;
  letter-spacing: $letter-spacing-med;
  text-transform: uppercase;

  a {
    text-decoration: none;
    color: $text-primary;
  }

  @include onMobile(#{&}) {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
  }
}

.specialty {
  font-weight: normal;
  font-size: 18px;
  letter-spacing: $letter-spacing-bit;
  margin: 0 2rem 0 0;
  text-transform: capitalize;

  @include onMobile(#{&}) {
    font-size: $font-size-sm;
    margin: 0 0.5rem 0 0;
  }
}

.ratingWrapper {
  display: flex;
  align-items: center;
}

.about {
  margin: 1rem 0;
  color: $text-secondary;
  @include onMobile(#{&}) {
    margin: 0.2rem 0;
  }
}

.actions {
  width: 140px;
  text-align: center;

  @include onMobile(#{&}) {
    width: auto;
  }

  button {
    display: block;
    box-shadow: none;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-transform: inherit;

    @include onMobile(#{&}) {
      padding: 0;
      width: 40px;
      height: 40px;
      display: block;
      min-width: 40px;
    }
  }

  img {
    vertical-align: middle;
  }
}

.reviews {
  color: $text-tertiary;
  font-weight: bold;
  font-size: $font-size-sm;
  margin-left: 1rem;
  white-space: nowrap;

  @include onMobile(#{&}) {
    font-weight: 500;
    font-size: small;
    margin-left: 0.5rem;
  }
}

.avatar {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.cost {
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: $font-size-sm;
  color: $text-tertiary;
  letter-spacing: $letter-spacing-bit;
}
