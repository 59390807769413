@import "../../variables.scss";

.page {
  display: flex;
  @include onMobile(#{&}){
    flex-direction: column;
  }
}

.sidebar {
  width: 300px;
  margin-right: 40px;
  @include onMobile(#{&}){
    width: auto;
    margin: 0;
    text-align: center;
  }
}

.stickySidebar {
  position: sticky;
  top: 10px;
  height: 300px;

}

.main {
  width: calc(100% - 340px);
  @include onMobile(#{&}) {
    width: 100%;
  }
}

.name {
  line-height: 1.2em;
  margin: 1rem 0 0.7rem 0;
  font-size: $font-size-h5;
  font-weight: 700;
  letter-spacing: $letter-spacing-med;
  text-transform: uppercase;

  a {
    text-decoration: none;
    color: $text-primary;
  }
}

.specialty {
  color: $text-secondary;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: $letter-spacing-bit;
  margin: 0 0 .8rem 0;
  text-transform: capitalize;
}

.about {
  margin: 1rem 0;
  color: $text-secondary;

  @include onMobile(#{&}){
    padding: 0 1rem;
  }
}

.actions {
  width: 150px;
  text-align: center;
  margin-left: auto;
  margin-right: 0;
  @include onMobile(#{&}){
    width: auto;
    margin: 0 auto;
  }
  button {
    padding: 0.7rem 2rem;
    display: block;
    box-shadow: none;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-transform: inherit;
  }

  img {
    vertical-align: middle;
  }
}

:global(.isMobile) {
  .btnAddReview {
    position: absolute;
    position: absolute;
    right: 1rem;
    top: 80px;
  }
}

.cost {
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: normal;
  color: $text-tertiary;
  letter-spacing: $letter-spacing-bit;
}

.availableTime {
  text-align: center;
  color: white;
  background: $primary-color;
  padding: 5rem 0;
  user-select: none;
}

.weekNavigator{
  align-items: center;
  user-select: none;

  :global(button){
    min-width: 0;
    padding: 0;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: transparent !important;
    box-shadow: none;
    margin: 0 1rem;
  }
}

.daysWrapper {
  display: flex;
  justify-content: center;
  @include onMobile(#{&}){
    flex-direction: column;
  }
}

.day {
  flex: 1;
  padding: 1rem 0.7rem;
  text-align: center;
  @include onMobile(#{&}){
    display: flex;
align-items: center;
    flex-direction: row;
  }
}

.dayName {
  font-weight: bold;
  display: inline-block;
  padding: 0.2rem 0.7rem;
  background: white;
  border-radius: 30px;
  color: $primary-color;
  margin-bottom: 1rem;

  @include onMobile(#{&}){
    width: 50px;
height: 50px;
line-height: 50px;
padding: 0;
margin-right: 1rem;
  }
}

.times {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;

  li {
    margin-bottom: 0.5rem;
    @include onMobile(#{&}) {
      display:  inline-block;
      margin-right: 1rem;
    }
  }
}

.reviews{
  @include onMobile(#{&}){
    :global(.Rating){
      justify-content: center;
    }
  }
}
.pagination {
  margin-top: 1rem;
  flex-direction: row;
  justify-content: end;
}

.featureTitle {
  font-weight: 600;
  margin: 0.5rem 0;
}

.feature {
  p {
    margin: 0;
    font-size: $font-size-p2;
  }
}
