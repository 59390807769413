@import "../../variables.scss";

.page {
  display: flex;
}

.sidebar {
  width: 280px;
  margin-right: 20px;
}

.main {
  width: calc(100% - 300px);
  @include onMobile(#{&}){
    width: 100%;
  }
}
