@import "../../variables.scss";

.MedicalRecord {
  .record {
    background: #fafafa;
    padding: 1rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 6px 8px 0px #33333314;
    border-radius: $border-radius;

    &.file {
      width: 90%;
      @media #{$large-and-up} {
        width: 50%;
      }
    }

    &.text {
      width: 100%;
      @media #{$large-and-up} {
        width: 80%;
      }
    }
  }
  .meta {
    justify-content: right;
    text-align: right;
    border-top: $border-regular;
    padding: 0.5rem 0 0 0;
  }
}
