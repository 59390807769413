@import "../../variables.scss";

.page {
  display: flex;
}

.searchWrapper {
  background: $primary-color;
  color: white;
  & > div {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
}

.input {
  flex: 1;
  background: white;
  height: 40px;
  line-height: 40px;
  border-radius: $border-radius;
  padding: 0 1rem;
  margin: 0 1rem;
}

button.searchButton {
  background: white !important;
  width: 40px;
  height: 40px;
  margin-left: 1rem;
}

.sidebar {
  width: 280px;
  margin-right: 20px;
}

.main {
  width: calc(100% - 300px);
}
:global(.isMobile) {
  .main {
    width: 100%;
  }
}

.pagination {
  margin-top: 1rem;
  flex-direction: row;
  justify-content: end;
  @include onMobile(#{&}){
    justify-content: center;
  }
}
