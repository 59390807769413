.cardNumberWrapper {
  position: relative;

  .methods {
    position: absolute;
    right: 1rem;
    top: 1.15rem;

    img{
      width: 30px;
      height: auto;
      margin-left: 2px;
    }
  }
}
