@import "../../variables.scss";

.Settings {
  .MuiFormControl-root,
  .MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0.5rem;
  }

  .changePassword {
    button {
      text-transform: inherit;
      color: $text-primary;
    }
  }

  .changePassword button,
  .MuiInputLabel-formControl,
  .MuiFormControlLabel-label {
    color: $text-primary;
    transform: none;
    font-size: 1rem;
    font-weight: bold;
  }

  .cost {
    .MuiInput-root {
      &:before,
      &:after {
        border-bottom: $border-regular;
      }
    }
    input {
      border: none;
      margin-top: 0.5rem;
    }
  }

  .charity {
    .MuiFormControlLabel-label {
      flex: 1;
    }
  }

  .MuiPickersDay-root {
    &.selected {
      background: rgba($primary-color, 0.2);
    }

    &.Mui-selected {
      background: var(--primary-color);
    }
  }

  .hourSelector {
    text-align: center;
    @media #{$medium-and-up} {
      margin-left: 2rem;
      text-align: left;
    }
  }

  .workingHoursCalendar {
    display: flex;
    flex-direction: column;
    @media #{$medium-and-up} {
      flex-direction: row;
    }
  }

  .workingHoursCalendar__hours {
    padding: 0 1rem;
    text-align: center;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    // column-count: 2;
    // column-gap: 0.5rem;

    // @media #{$medium-and-up} {
    //   column-count: 2;
    // }

    // @media #{$large-and-up} {
    //   column-count: 3;
    // }

    .weeklyCheckWrapper {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;

      .MuiCheckbox-root {
        padding: 0.2rem;
      }

      .MuiSvgIcon-root {
        color: white;
      }
    }
  }

  .timeWrapper {
    align-items: center;
    justify-content: space-between;
    background: whitesmoke;
    border-radius: 30px;
    margin: 0 auto 0.5rem auto;
    color: $text-primary;
    width: 240px;
    text-align: center;
    box-shadow: $shadow-d1;
    cursor: pointer;
    transition: 200ms;
    height: 35px;
    line-height: 35px;
    padding: 0rem 1rem;
    user-select: none;

    &:hover {
      background: rgb(236, 236, 236);
    }

    &.active {
      background: var(--primary-color);
      color: white;
      //transform: translateY(5px) scale(0.9);

      & + div + div + div,
      & + div + div,
      & + div {
        //transform: translateY(0px) scale(0.9);
        //background: $primary-color;
        color: rgb(168, 168, 168);
        pointer-events: none;
      }

      & + div + div {
        //transform: translateY(-5px) scale(0.9);
        //transition-delay: 50ms;
      }
      & + div + div + div {
        //transform: translateY(-8px) scale(0.9);
        //transition-delay: 70ms;
        //pointer-events: inherit;
        &.active {
          //transform: translateY(0px) scale(1);
        }
      }
    }

    &:nth-last-child(-n + 3),
    &.past {
      pointer-events: none !important;
      box-shadow: none;
      color: $text-tertiary;
    }
  }
}
