@import "../../variables.scss";

.userMenu {
  .MuiMenuItem-root {
    &.active {
      background-color: rgba($primary-color, 0.06);
      color: $primary-color;
      path {
        fill: $primary-color;
      }
    }
  }

  svg {
    margin-right: 0.5rem;
  }
}
