@import "../../variables.scss";

.MedicalRecords {
  .recordLink {
    width: 50%;
  }

  .recordTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recordAuthor{
    @at-root .isMobile &{
      font-size: $font-size-sm;
    }
  }
}

.questionnaires{
  background: linear-gradient(45deg, rgb(250, 243, 210), rgb(252, 228, 202)) repeat scroll 0% 0%;
  border-radius: 4px;
  color: rgb(41, 38, 0);
  margin-bottom: 2rem;

  h3{
    font-size: $font-size-h6;
    margin: 0;
  }

  .items{
    display: flex;
    flex-wrap: nowrap;
  }

  .questionnaires__item{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem 1.5rem .5rem 1rem;
    margin: 1rem;

    h4{
      margin: 0
    }

    .actions{
      margin-top: .5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a,button{
      white-space: nowrap;
      color: $text-primary;
      text-decoration: none;
      font-size: $font-size-sm;
      text-transform: inherit;
    }
  }
}