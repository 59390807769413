@import '../../variables.scss';

.pagination{
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    
    button{
        background: transparent;
        border: none;
        font-weight: bold;
        font-size: 1rem;
        text-transform: inherit;
        padding: .1rem 1rem;
        margin: 0 .5rem;
        color: $text-primary;
        border-radius: $border-radius;

        &:disabled{
            color: $text-tertiary;
        }
    }
}