@import "../../variables.scss";

.heroOuter {
  background-image: linear-gradient(45deg, rgb(106, 156, 133), rgb(65, 199, 105));
}

.hero {
  position: relative;
  padding: 1rem;
  height: 150vw;
  color: white;
  background-image: url("../../assets/images/hero-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 10% center;

  @media #{$medium-and-up} {
    height: 44vw;
    background-position: center;
  }

  // fix
  // background-size: 100% 100%;
  // width: 100vw;
  // height: 630px;
}

.search {
  border-radius: $border-radius;
  background-color: transparent;
  border: 1px solid white;
  display: flex;
  align-items: center;
  max-width: 70vw;
  height: 42px;

  @media #{$medium-and-up} {
    max-width: 400px;
    margin: 0 auto;
  }

  input {
    font-size: 1rem;
    flex: 1;
    background: transparent;
    border: none;
    color: white;
    height: 42px;
    width: 100%;
  }
}

.searchIcon {
  filter: invert(1);
  width: 42px;
  height: 42px;
  padding: 0.7rem;
}

.searchButton {
  background: rgba(255, 255, 255, 0.5);
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: $border-radius;
  padding: 0;
  border: none;

  img {
    opacity: 0.7;
    vertical-align: middle;
  }
}

.heroContent {
  max-width: 700px;
  margin-left: auto;
  text-align: left;
  letter-spacing: $letter-spacing-med;
  height: 130vw;
  display: flex;
  flex-direction: column;

  @media #{$medium-and-up} {
    display: block;
    padding: 2rem 0;
    text-align: center;
    height: auto;
  }

  @media #{$large-and-up} {
    padding: 8rem 0;
  }

  h1 {
    flex: 1;
    font-size: 42px;
    line-height: 3rem;
    @media #{$medium-and-up} {
      flex: none;
    }
  }

  h5 {
    letter-spacing: $letter-spacing-low;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 1366px) {
    position: relative;
    top: 5vh;
  }

  @media screen and (min-width: 2500px) {
    position: relative;
    top: 15vh;
  }
}

.service {
  // position: relative;
  text-decoration: none;
  text-align: center;
  img {
    max-width: 100%;
    width: 400px;
    height: 200px;
    object-fit: cover;
    border-radius: $border-radius;
    background-color: rgb(243, 243, 243);
  }
}


.mobileServicesHeader {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9;
  margin-top: 3rem;
  color: white;
  padding: 1rem;
  border-radius: 0 $border-radius $border-radius 0;
}

.serviceTitle {
  letter-spacing: $letter-spacing-low;
  font-weight: 400;
  margin: 1rem auto;
  color: $text-primary;
  font-size: $font-size-h5;
}

.consultant {
  background-image: url("../../assets/images/home-consultant-cta.jpg");
  background-color: #ddd;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 550px;
  color: white;
  padding-top: 220px;
  margin: 0;
  margin-top: -5px;

  @media #{$medium-and-up} {
    height: 400px;
  }

  h5 {
    font-size: $font-size-lg;
    font-weight: normal;
    margin: 0;
  }
  h2 {
    font-size: $font-size-2xl-r;
    margin: 1.5rem 0;
    font-weight: bold;
  }
}

.consultantCover {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  z-index: 9;
  margin-top: 3rem;
  color: white;
  padding: 1rem;
  border-radius: 0 $border-radius $border-radius 0;

  @media #{$medium-and-up} {
    background: #33333380;
    float: left;
    border-radius: $border-radius;
    padding: 1rem;
    position: static;
    margin: 0;
  }

  h2 {
    font-size: 40px;
  }
}

.hiwItem {
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: $font-size-p2;
    margin: 0;
    @media #{$large-and-up} {
      font-weight: normal;
      font-size: $font-size-h5;
      letter-spacing: $letter-spacing-low;
    }
  }

  p {
    margin: 0.5rem 0;
    color: $text-secondary;
  }

  img{
    width: auto;
    height: 62px;
    margin-bottom: 1rem;
  }
}


.topTherapists {
  background-color: $primary-color;
  color: white;
  position: relative;
}

.topTherapistsRow{
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;

  @media #{$medium-and-up} {
    flex-wrap: wrap;
    overflow-x: inherit;
  }
}

.mobileTherapistsHeader {
  @extend .mobileServicesHeader;
  margin: 0;
  margin-left: -1rem;
  position: relative;
  display: inline-block;

  h2 {
    font-size: 38px;
  }
}

.therapist {
  margin-bottom: 2rem;
  text-decoration: none;
  color: white;
  width: 70vw;
  @media #{$medium-and-up} {
    width: auto;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: $border-radius;
    box-shadow: 0px 16px 40px 0px #00000066, 0px 4px 4px 0px #0000001a;
  }

  h3 {
    font-size: $font-size-h5;
    text-transform: capitalize;
  }

  h4{
    text-transform: capitalize;
  }
}

.spec {
  font-weight: 500;
  font-size: 18px;
}

.whyUsDescription {
  text-align: center;
  color: $text-secondary;
  max-width: 540px;
  margin: 0 auto;
}

.whyUsItem {
  text-align: center;
  margin-bottom: 2rem;

  h3 {
    margin: 0.5rem 0;
    font-size: $font-size-h6;
  }

  p {
    text-align: center;
    color: $text-secondary;
    max-width: 540px;
    margin: 0 auto;
  }

  img{
    width: auto;
    height: 58px;
    margin-bottom: .5rem;
  }
}
